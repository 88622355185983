// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kickstarter-js": () => import("../src/pages/kickstarter.js" /* webpackChunkName: "component---src-pages-kickstarter-js" */),
  "component---src-pages-play-js": () => import("../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-subscribe-js": () => import("../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-survey-js": () => import("../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */)
}

